import { generatePath } from 'react-router';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner';
import {
    useNavigateOnError,
    useNavigateOnSuccess,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { useParkingaboServerWrite } from '../../api/ParkingaboApi';
import { useParamOrNull } from 'dg-web-shared/lib/ReactRouterHelpers';
import { useEffect } from 'react';

export function EmailVerificationRoute({
    refetchUser,
}: {
    refetchUser?: () => void;
}) {
    const token = useParamOrNull('token');

    const [submitState, submit] = useParkingaboServerWrite<
        { token: string },
        never
    >(() => ({
        url: '/ui-api/parkingabo/user/confirm',
    }));

    useEffect(() => {
        if (token) {
            submit({ token });
        }
    }, [token]);

    useNavigateOnSuccess(submitState, '/login/email-confirmed');
    useServerSuccessEffect(submitState, refetchUser ? refetchUser : () => null);

    useNavigateOnError(
        submitState,
        generatePath('/register/link-expired/:token', { token }),
    );

    return (
        <PresetLoadingSpinner
            preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
        />
    );
}
