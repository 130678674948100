import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboHeader } from '../../../components/layout/ParkingaboHeader';
import { ParkingaboLayout } from '../../../components/layout/ParkingaboLayout';
import { OnboardingStepType, useOnboarding } from './OnboardingConfig';
import {
    PaymentMethodForm,
    PaymentMethodType,
} from '../../../components/forms/PaymentMethodForm';
import { Outlet, useNavigate } from 'react-router-dom';
import { ParkingaboButton } from '../../../components/layout/ParkingaboButton.tsx';
import {
    RequestMethod,
    RequestStatus,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi.tsx';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import { GenericErrorPopup } from '../../public/ParkingaboSharedRegistrationValidationRoute.tsx';
import { useCustomerTenant } from '../../../components/CustomerTenantProvider.tsx';

export function OnboardingPaymentMethodRoute() {
    const { refetchUser, logout, linkedUserCount } = useCustomerTenant();
    const theme = useTheme();
    const navigate = useNavigate();
    const { config } = useOnboarding();
    const currentStep = config.steps.findIndex(
        c => c.type === OnboardingStepType.PAYMENT,
    );
    const totalSteps = config.steps.length;

    const [onboardingCompleteState, setOnboardingComplete] =
        useParkingaboServerWrite<null, { success: boolean }>(() => ({
            url: '/ui-api/parkingabo/user/self/set-onboarding-complete',
            method: RequestMethod.POST,
        }));

    useServerSuccessEffect(onboardingCompleteState, () => {
        refetchUser();
    });

    if (onboardingCompleteState.status === RequestStatus.NEVER_EXECUTED) {
        setOnboardingComplete(null);
    } else if (onboardingCompleteState.status === RequestStatus.PENDING) {
        return (
            <PresetLoadingSpinner
                preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            />
        );
    } else if (onboardingCompleteState.status === RequestStatus.ERROR) {
        return <GenericErrorPopup onConfirm={() => logout()} />;
    }

    return (
        <ParkingaboLayout
            style={{
                justifyContent: 'flex-start',
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            }}
        >
            <ParkingaboHeader />
            <Typography
                variant="h1"
                sx={{
                    marginTop: theme => theme.spacing(3),
                }}
            >
                <Localized
                    de="Zahlungsmittel"
                    fr="Moyen de paiement"
                    it="Mezzo di pagamento"
                    en="Payment mean"
                />
            </Typography>
            {totalSteps > 1 && (
                <Typography
                    variant="h3"
                    sx={theme => ({
                        marginBottom: theme.spacing(5),
                        marginTop: theme.spacing(2),
                    })}
                >
                    <Localized
                        de={`Konfiguration (${
                            currentStep + 1
                        } von ${totalSteps})`}
                        fr={`Configuration (${
                            currentStep + 1
                        } de ${totalSteps})`}
                        it={`Configurazione (${
                            currentStep + 1
                        } di ${totalSteps})`}
                        en={`Setup (${currentStep + 1} of ${totalSteps})`}
                    />
                </Typography>
            )}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                <PaymentMethodForm
                    paymentMethodType={PaymentMethodType.ONBOARDING}
                />

                <Box
                    sx={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '48%',
                    }}
                >
                    <ParkingaboButton
                        variant="outlined"
                        onClick={
                            linkedUserCount > 1 ? () => navigate('/') : logout
                        }
                        fullWidth
                        sx={{ marginRight: 1 }}
                    >
                        <Localized
                            de="Abbrechen"
                            fr="Annuler"
                            it="Annulla"
                            en="Cancel"
                        />
                    </ParkingaboButton>
                </Box>
            </Box>
            <Outlet />
        </ParkingaboLayout>
    );
}
