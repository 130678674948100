import { useParamOrNull } from 'dg-web-shared/lib/ReactRouterHelpers.ts';
import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
} from '../../api/ParkingaboApi.tsx';
import { Navigate, useNavigate } from 'react-router-dom';
import {
    RequestMethod,
    useNavigateOnError,
    useNavigateOnSuccess,
    useServerErrorEffect,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { useState } from 'react';
import {
    AuthedValidationContent,
    AuthValidationErrorReason,
    AuthValidationSuccess,
    AuthValidationSuccessResponse,
    ConfirmEmailPopup,
    LinkLoginData,
    LinkLoginPopup,
    OnboardingCompletePopup,
    OnboardingPendingPopup,
    PublicValidationErrorPopup,
    PublicValidationErrorReason,
    PublicValidationResponse,
    PublicValidationState,
    useResentVerificationMail,
    ValidationOrigin,
    ValidationState,
} from './ParkingaboSharedRegistrationValidationRoute.tsx';
import { ParkingaboAsyncLoadedSection } from '../../components/layout/ParkingaboAsyncLoadedSection.tsx';
import { LoggedInRouteCompProps } from '../RouteUtils.tsx';

export function usePublicBadgeValidationState(badgeLabelNr: string | null) {
    const [badgeValidationState] = useParkingaboServerFetch<
        PublicValidationResponse,
        { badgeLabelNr: string },
        { reason: PublicValidationErrorReason }
    >(
        ({ badgeLabelNr }) => ({
            url: `/ui-api/parkingabo/badge/${badgeLabelNr}/validation`,
        }),
        badgeLabelNr ? { badgeLabelNr: badgeLabelNr } : null,
    );

    return [badgeValidationState];
}

export function PublicParkingaboBadgeValidationRoute() {
    const badgeLabelNr = useParamOrNull('badgeLabelNr');
    const navigate = useNavigate();

    const [badgeValidationState] = usePublicBadgeValidationState(badgeLabelNr);

    const { anotherLinkState, requestAnotherLink } =
        useResentVerificationMail();
    useNavigateOnSuccess(
        anotherLinkState,
        `/register-with-badge/${badgeLabelNr}/email-sent`,
    );

    const [removeBadgeRegistrationState, removeBadgeRegistration] =
        useParkingaboServerWrite<
            {
                badgeLabelNr: string;
                email: string;
            },
            never
        >(() => ({
            url: `/ui-api/parkingabo/badge/${badgeLabelNr}/remove-badge-registration`,
        }));
    useNavigateOnSuccess(
        removeBadgeRegistrationState,
        `/register-with-badge/${badgeLabelNr}`,
    );
    useNavigateOnError(removeBadgeRegistrationState, '/login');

    if (badgeLabelNr === null) {
        return <Navigate to="/login" replace />;
    }

    return (
        <ParkingaboAsyncLoadedSection
            state={badgeValidationState}
            render={data => {
                switch (data.validationState) {
                    case PublicValidationState.NO_LOGIN:
                        return (
                            <Navigate
                                to={`/register-with-badge/${badgeLabelNr}`}
                            />
                        );
                    case PublicValidationState.EMAIL_CONFIRMATION_PENDING:
                        return (
                            <ConfirmEmailPopup
                                emailToBeConfirmed={data.email!}
                                onConfirm={() => () =>
                                    requestAnotherLink({
                                        email: data.email!,
                                    })
                                }
                                onAbort={() =>
                                    removeBadgeRegistration({
                                        badgeLabelNr: badgeLabelNr!,
                                        email: data.email!,
                                    })
                                }
                            />
                        );
                    case PublicValidationState.ONBOARDING_PENDING:
                        return (
                            <OnboardingPendingPopup
                                onConfirm={() => navigate('/login')}
                                origin={ValidationOrigin.BADGE}
                            />
                        );
                    case PublicValidationState.ONBOARDING_COMPLETED:
                        return (
                            <OnboardingCompletePopup
                                onConfirm={() => navigate('/login')}
                                origin={ValidationOrigin.BADGE}
                            />
                        );
                }
            }}
            renderError={errorData => (
                <PublicValidationErrorPopup
                    error={errorData}
                    origin={ValidationOrigin.BADGE}
                />
            )}
        />
    );
}

export function AuthedParkingaboBadgeValidationRoute({
    logout,
}: LoggedInRouteCompProps) {
    const badgeLabelNr = useParamOrNull('badgeLabelNr');
    const navigate = useNavigate();
    const [linkLoginData, setLinkLoginData] = useState<LinkLoginData | null>(
        null,
    );
    const [validationState, setValidationState] = useState<ValidationState>(
        ValidationState.LOADING,
    );

    const [badgeValidationState] = useParkingaboServerFetch<
        AuthValidationSuccess,
        { badgeLabelNr: string },
        { reason: AuthValidationErrorReason }
    >(
        ({ badgeLabelNr }) => ({
            url: `/ui-api/parkingabo/user/badge/${badgeLabelNr}/validation`,
        }),
        badgeLabelNr ? { badgeLabelNr: badgeLabelNr } : null,
    );

    const [linkCustomerToLoginState, linkCustomerToLogin] =
        useParkingaboServerWrite<{ badgeLabelNr: string }, object>(() => ({
            url: `/ui-api/parkingabo/user/badge/${badgeLabelNr}/link-login`,
            method: RequestMethod.PUT,
        }));

    useServerSuccessEffect(badgeValidationState, data => {
        switch (data.response) {
            case AuthValidationSuccessResponse.LINK_LOGIN:
                setLinkLoginData({
                    email: data.email,
                    operatorName: data.operatorName,
                });
                break;
            case AuthValidationSuccessResponse.LOGGED_IN:
                navigate(`/${data.tenantId}`);
                break;
        }
    });

    useServerErrorEffect(badgeValidationState, (_, data) => {
        if (data === null) {
            setValidationState(ValidationState.GENERIC_ERROR);
        } else {
            switch (data.reason) {
                case AuthValidationErrorReason.NOT_FOUND:
                    setValidationState(ValidationState.NOT_FOUND);
                    break;
                case AuthValidationErrorReason.ALREADY_REGISTERED_ON_OPERATOR:
                    setValidationState(
                        ValidationState.ALREADY_REGISTERED_ON_OPERATOR,
                    );
                    break;
            }
        }
    });

    if (badgeLabelNr === null) {
        return <Navigate to="/" replace />;
    }

    if (linkLoginData !== null) {
        return (
            <LinkLoginPopup
                linkLoginData={linkLoginData}
                onConfirm={() =>
                    linkCustomerToLogin({ badgeLabelNr: badgeLabelNr })
                }
                linkCustomerToLoginState={linkCustomerToLoginState}
                onAbort={() => logout()}
            />
        );
    }

    return (
        <AuthedValidationContent
            validationState={validationState}
            origin={ValidationOrigin.BADGE}
        />
    );
}
