import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useState } from 'react';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from 'react-router-dom';
import { useParkingaboServerWrite } from '../api/ParkingaboApi';
import {
    useServerErrorEffect,
    useServerFetch,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ParkingaboUser } from '../shared/ParkingaboModels';
import { VehiclesRoute } from './authorized/vehicles/VehiclesRoute';
import { VehiclesDetailRoute } from './authorized/vehicles/VehiclesDetailRoute';
import { AddVehicleRoute } from './authorized/vehicles/AddVehicleRoute';
import { ProductsAddOutlet } from './authorized/products/ProductsAddOutlet';
import { ProductsConfigurationOutlet } from './authorized/products/ProductsConfigurationOutlet';
import { ProductsSelectionOutlet } from './authorized/products/ProductsSelectionOutlet';
import { ProductsRoute } from './authorized/products/ProductsRoute';
import { ProductsDetailOutlet } from './authorized/products/ProductsDetailOutlet';
import { AddPaymentMethodRoute } from './authorized/payment/AddPaymentMethodRoute';
import { PaymentMethodType } from '../components/forms/PaymentMethodForm';
import { PaymentAbortedRoute } from './authorized/payment/PaymentAbortedRoute';
import { PaymentMethodCheckRoute } from './authorized/payment/PaymentMethodCheckRoute';
import { OnboardingPaymentMethodRoute } from './authorized/onboarding/OnboardingPaymentMethodRoute';
import { OnboardingUserDataRoute } from './authorized/onboarding/OnboardingUserDataRoute';
import { OnboardingVehicleRoute } from './authorized/onboarding/OnboardingVehicleRoute';
import { OnboardingSuccessRoute } from './authorized/onboarding/OnboardingSuccessRoute';
import { HomeRoute } from './authorized/HomeRoute';
import { TenantSelectionRoute } from './authorized/TenantSelectionRoute';
import { CustomerTenantContextProvider } from '../components/CustomerTenantProvider.tsx';
import { ProductsAccessChecker } from '../components/ProductsAccessChecker';
import { SettingsRoute } from './authorized/settings/SettingsRoute';
import { PaymentSettingsRoute } from './authorized/settings/PaymentSettingsRoute';
import { EmailResentConfirmationRoute } from './authorized/settings/EmailResentConfirmationRoute';
import { AddPaymentSuccessRoute } from './authorized/settings/AddPaymentSuccessRoute';
import { EditLanguageRoute } from './authorized/settings/EditLanguageRoute';
import { EditNameRoute } from './authorized/settings/EditNameRoute';
import { EditEmailRoute } from './authorized/settings/EditEmailRoute';
import { EditPasswordRoute } from './authorized/settings/EditPasswordRoute';
import { EditProfileRoute } from './authorized/settings/EditProfileRoute';
import { LoginRoute, TenantLoginRoute } from './public/LoginRoute';
import {
    ParkingaboRegistrationWithBadge,
    ParkingaboRegistrationWithCustomer,
    ParkingaboTenantRegistrationRoute,
} from './public/ParkingaboRegistrationRoute';
import { RegistrationConfirmationRoute } from './public/RegistrationConfirmationRoute';
import {
    RegistrationLinkExpiredRoute,
    RegistrationLinkExpiredWithBadge,
    RegistrationLinkExpiredWithCustomer,
} from './public/RegistrationLinkExpiredRoute';
import { RequestPasswordResetRoute } from './public/RequestPasswordResetRoute';
import { PasswordRequestSentSuccessRoute } from './public/PasswordRequestSentSuccessRoute';
import { ResetPasswordRoute } from './public/ResetPasswordRoute';
import { ResetPasswordSuccessRoute } from './public/ResetPasswordSuccessRoute';
import { ResetPasswordTokenErrorRoute } from './public/ResetPasswordTokenErrorRoute';
import { EmailVerificationRoute } from './public/EmailVerificationRoute';
import {
    AccountStatementItemDetail,
    AccountStatementOutlet,
} from './authorized/account-statement/AccountStatementOutlet';
import { EmailConfirmedModalRoute } from './public/EmailConfirmedModalRoute';
import { ProductsPurchaseAccessChecker } from '../components/ProductsPurchaseAccessChecker';
import { AccountLockedMessageRoute } from './authorized/AccountLockedMessageRoute';
import { OnboardingRequiredChecker } from '../components/OnboardingRequiredChecker';
import {
    NewPaymentRegistrationRequiredRoute,
    PaymentOverdueRegistrationRequiredRoute,
} from './authorized/payment/NewPaymentRegistrationRequiredRoute';
import { ReplacePaymentRoute } from './authorized/settings/ReplacePaymentRoute';
import {
    AuthedParkingaboBadgeValidationRoute,
    PublicParkingaboBadgeValidationRoute,
} from './public/ParkingaboBadgeValidationRoute.tsx';
import { OnboardingProvider } from './authorized/onboarding/OnboardingConfig.tsx';
import { ParkingaboPrivacy } from './Privacy.tsx';
import { UnauthenticatedTenantContextProvider } from '../components/layout/UnauthenticatedTenantProvider.tsx';
import { NativeContextProdivder, setAppToken } from '../components/Native.tsx';
import { QRCodeParsingErrorMessage } from './public/QRCodeParsingErrorMessage.tsx';
import { LandingPage } from './public/LandingPage.tsx';
import {
    AuthedParkingaboCustomerValidationRoute,
    PublicParkingaboCustomerValidationRoute,
} from './public/ParkingaboCustomerValidationRoute.tsx';
import { AuthedParkingaboTenantRegistrationValidationRoute } from './public/ParkingaboTenantRegistrationValidationRoute.tsx';

export interface ParkingaboLogin {
    loggedIn: boolean;
    logout: () => void;
    appReadyToBeShown: boolean;
    linkedUsers: ParkingaboUser[];
    refetchLogin: () => void;
}

export function useLogin(): ParkingaboLogin {
    const [loggedIn, setLoggedIn] = useState(false);
    const [appReadyToBeShown, setAppReadyToBeShown] = useState(false);

    const [logoutState, logout] = useParkingaboServerWrite(() => ({
        url: '/ui-api/parkingabo/session/logout',
    }));

    useServerSuccessEffect(logoutState, () => {
        setLoggedIn(false);
        setAppToken(null);
        window.location.reload();
    });

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    const [loginState, refetchLogin] = useServerFetch<
        { linkedUsers: ParkingaboUser[] },
        object
    >(
        () => ({
            url: `/ui-api/parkingabo/login`,
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            },
        }),
        {},
    );

    useServerSuccessEffect(loginState, response => {
        if (response.linkedUsers.length < 1) {
            logout();
            return;
        }
        setLoggedIn(true);
        setAppReadyToBeShown(true);
    });

    useServerErrorEffect(loginState, statusCode => {
        if (statusCode === 401) {
            setLoggedIn(false);
            setAppReadyToBeShown(true);
        }
    });

    return {
        loggedIn,
        logout: () => logout(),
        appReadyToBeShown: appReadyToBeShown,
        linkedUsers: loginState.data?.linkedUsers ?? [],
        refetchLogin,
    };
}

export function AppRoutes({ login }: { login: ParkingaboLogin }) {
    const theme = useTheme();

    if (!login.appReadyToBeShown) {
        return (
            <div
                style={{
                    backgroundColor: theme.palette.grey[400],
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    if (!login.loggedIn) {
        return <RouterProvider router={publicRouter(login)} />;
    }

    return (
        <AuthedRoutes
            linkedUsers={login.linkedUsers}
            refetchLogin={login.refetchLogin}
            logout={login.logout}
        />
    );
}

function publicRouter(login: ParkingaboLogin) {
    return createBrowserRouter(
        [
            {
                element: <NativeContextProdivder />,
                children: [
                    { element: <LandingPage />, index: true },
                    {
                        path: '/privacy',
                        element: <ParkingaboPrivacy />,
                    },
                    {
                        path: '/login',
                        element: <LoginRoute login={login} />,
                        children: [
                            {
                                path: 'email-confirmed',
                                element: (
                                    <EmailConfirmedModalRoute isRegistration />
                                ),
                            },
                            {
                                path: 'qr-code-parsing-failed',
                                element: <QRCodeParsingErrorMessage />,
                            },
                        ],
                    },
                    {
                        path: '/verify/:token',
                        element: <EmailVerificationRoute />,
                    },
                    {
                        path: '/:tenantId',
                        element: <UnauthenticatedTenantContextProvider />,
                        children: [
                            {
                                path: 'login',
                                element: <TenantLoginRoute login={login} />,
                                children: [
                                    {
                                        path: 'email-confirmed',
                                        element: (
                                            <EmailConfirmedModalRoute
                                                isRegistration
                                            />
                                        ),
                                    },
                                ],
                            },
                            {
                                path: 'register',
                                element: <ParkingaboTenantRegistrationRoute />,
                                children: [
                                    {
                                        path: 'email-sent',
                                        element: (
                                            <RegistrationConfirmationRoute />
                                        ),
                                    },
                                    {
                                        path: 'link-expired/:token',
                                        element: (
                                            <RegistrationLinkExpiredRoute />
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: '/request-password-reset',
                        element: <RequestPasswordResetRoute />,
                        children: [
                            {
                                path: 'success',
                                element: <PasswordRequestSentSuccessRoute />,
                            },
                        ],
                    },
                    {
                        path: '/reset-password/:token',
                        element: <ResetPasswordRoute />,
                        children: [
                            {
                                path: 'error',
                                element: <ResetPasswordTokenErrorRoute />,
                            },
                            {
                                path: 'success',
                                element: <ResetPasswordSuccessRoute />,
                            },
                        ],
                    },
                    {
                        path: '/b/:badgeLabelNr',
                        element: <PublicParkingaboBadgeValidationRoute />,
                    },
                    {
                        path: '/register-with-badge/:badgeLabelNr',
                        element: <ParkingaboRegistrationWithBadge />,
                        children: [
                            {
                                path: 'email-sent',
                                element: <RegistrationConfirmationRoute />,
                            },
                            {
                                path: 'link-expired/:token',
                                element: <RegistrationLinkExpiredWithBadge />,
                            },
                        ],
                    },
                    {
                        path: '/c/:customerNr',
                        element: <PublicParkingaboCustomerValidationRoute />,
                    },
                    {
                        path: '/register-with-customer/:customerNr',
                        element: <ParkingaboRegistrationWithCustomer />,
                        children: [
                            {
                                path: 'email-sent',
                                element: <RegistrationConfirmationRoute />,
                            },
                            {
                                path: 'link-expired/:token',
                                element: (
                                    <RegistrationLinkExpiredWithCustomer />
                                ),
                            },
                        ],
                    },
                    {
                        path: '*',
                        element: <Navigate to="/login" replace />,
                    },
                ],
            },
        ],
        { future: { v7_normalizeFormMethod: true } },
    );
}

export function AuthedRoutes({
    logout,
    linkedUsers,
    refetchLogin,
}: {
    logout: () => void;
    linkedUsers: ParkingaboUser[];
    refetchLogin: () => void;
}) {
    return (
        <RouterProvider
            router={createBrowserRouter([
                {
                    index: true,
                    element: (
                        <TenantSelectionRoute
                            options={linkedUsers.map(linkedUser => ({
                                tenantId: linkedUser.tenantId,
                                tenantName: linkedUser.tenantName,
                            }))}
                            logout={logout}
                        />
                    ),
                },
                {
                    path: '/privacy',
                    element: <ParkingaboPrivacy />,
                },
                {
                    path: '/verify/:token',
                    element: (
                        <EmailVerificationRoute refetchUser={refetchLogin} />
                    ),
                },
                {
                    path: '/b/:badgeLabelNr',
                    element: (
                        <AuthedParkingaboBadgeValidationRoute logout={logout} />
                    ),
                },
                {
                    path: '/c/:customerNr',
                    element: (
                        <AuthedParkingaboCustomerValidationRoute
                            logout={logout}
                        />
                    ),
                },
                {
                    path: ':tenantId/register',
                    element: (
                        <AuthedParkingaboTenantRegistrationValidationRoute
                            logout={logout}
                        />
                    ),
                },
                {
                    path: ':tenantId',
                    element: (
                        <CustomerTenantContextProvider
                            linkedUsers={linkedUsers}
                            logout={logout}
                            refetchUser={refetchLogin}
                        />
                    ),
                    children: [
                        {
                            element: <OnboardingProvider />,
                            children: [
                                {
                                    element: <OnboardingRequiredChecker />,
                                    children: [
                                        {
                                            index: true,
                                            element: <HomeRoute />,
                                        },
                                        {
                                            path: 'link-expired/:token',
                                            element: (
                                                <RegistrationLinkExpiredRoute />
                                            ),
                                        },
                                        {
                                            path: 'email-confirmed',
                                            element: (
                                                <EmailConfirmedModalRoute />
                                            ),
                                        },
                                        {
                                            path: 'onboarding/success',
                                            element: <OnboardingSuccessRoute />,
                                        },
                                        {
                                            path: 'locked-account',
                                            element: (
                                                <AccountLockedMessageRoute />
                                            ),
                                        },
                                    ],
                                },
                                {
                                    children: [
                                        {
                                            path: 'onboarding',
                                            children: [
                                                {
                                                    path: 'vehicle',
                                                    element: (
                                                        <OnboardingVehicleRoute />
                                                    ),
                                                },
                                                {
                                                    path: 'user-data',
                                                    element: (
                                                        <OnboardingUserDataRoute />
                                                    ),
                                                },
                                                {
                                                    path: 'payment',
                                                    element: (
                                                        <OnboardingPaymentMethodRoute />
                                                    ),
                                                    children: [
                                                        {
                                                            path: 'aborted',
                                                            element: (
                                                                <PaymentAbortedRoute />
                                                            ),
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        {
                                            path: 'onboarding-with-badge',
                                            children: [
                                                {
                                                    path: 'payment',
                                                    element: (
                                                        <OnboardingPaymentMethodRoute />
                                                    ),
                                                    children: [
                                                        {
                                                            path: 'aborted',
                                                            element: (
                                                                <PaymentAbortedRoute />
                                                            ),
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        {
                                            path: 'register-payments/payment/:transactionId',
                                            element: (
                                                <PaymentMethodCheckRoute
                                                    paymentMethodType={
                                                        PaymentMethodType.REGISTER
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            path: 'onboarding-payments/payment/:transactionId',
                                            element: (
                                                <PaymentMethodCheckRoute
                                                    paymentMethodType={
                                                        PaymentMethodType.ONBOARDING
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            path: 'payment',
                                            children: [
                                                {
                                                    path: 'register',
                                                    element: (
                                                        <AddPaymentMethodRoute
                                                            paymentMethodType={
                                                                PaymentMethodType.REGISTER
                                                            }
                                                        />
                                                    ),
                                                    children: [
                                                        {
                                                            path: 'aborted',
                                                            element: (
                                                                <PaymentAbortedRoute />
                                                            ),
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        {
                                            element: (
                                                <OnboardingRequiredChecker />
                                            ),
                                            children: [
                                                {
                                                    path: 'vehicles',
                                                    element: <VehiclesRoute />,
                                                    children: [
                                                        {
                                                            path: 'add',
                                                            element: (
                                                                <AddVehicleRoute />
                                                            ),
                                                        },
                                                        {
                                                            path: ':vehicleId',
                                                            element: (
                                                                <VehiclesDetailRoute />
                                                            ),
                                                        },
                                                    ],
                                                },
                                                {
                                                    element: (
                                                        <ProductsAccessChecker />
                                                    ),
                                                    children: [
                                                        {
                                                            path: 'products',
                                                            element: (
                                                                <ProductsRoute />
                                                            ),
                                                            children: [
                                                                {
                                                                    path: 'payment-missing',
                                                                    element: (
                                                                        <NewPaymentRegistrationRequiredRoute />
                                                                    ),
                                                                },
                                                                {
                                                                    path: 'payment-overdue',
                                                                    element: (
                                                                        <PaymentOverdueRegistrationRequiredRoute />
                                                                    ),
                                                                },
                                                                {
                                                                    element: (
                                                                        <ProductsPurchaseAccessChecker />
                                                                    ),
                                                                    children: [
                                                                        {
                                                                            path: ':contractId',
                                                                            element:
                                                                                (
                                                                                    <ProductsDetailOutlet />
                                                                                ),
                                                                        },
                                                                        {
                                                                            path: 'add',
                                                                            element:
                                                                                (
                                                                                    <ProductsAddOutlet />
                                                                                ),
                                                                            children:
                                                                                [
                                                                                    {
                                                                                        index: true,
                                                                                        element:
                                                                                            (
                                                                                                <ProductsSelectionOutlet />
                                                                                            ),
                                                                                    },
                                                                                    {
                                                                                        path: ':contractTemplateId',
                                                                                        element:
                                                                                            (
                                                                                                <ProductsConfigurationOutlet />
                                                                                            ),
                                                                                    },
                                                                                ],
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                                {
                                                    path: 'account-statement',
                                                    element: (
                                                        <AccountStatementOutlet />
                                                    ),
                                                    children: [
                                                        {
                                                            path: ':bkBookingId',
                                                            element: (
                                                                <AccountStatementItemDetail />
                                                            ),
                                                        },
                                                    ],
                                                },
                                                {
                                                    path: 'settings',
                                                    element: <SettingsRoute />,
                                                },
                                                {
                                                    path: 'settings/payment',
                                                    element: (
                                                        <PaymentSettingsRoute />
                                                    ),
                                                    children: [
                                                        {
                                                            path: 'success',
                                                            element: (
                                                                <AddPaymentSuccessRoute />
                                                            ),
                                                        },
                                                        {
                                                            path: 'replace',
                                                            element: (
                                                                <ReplacePaymentRoute />
                                                            ),
                                                        },
                                                    ],
                                                },
                                                {
                                                    path: 'settings/user',
                                                    element: (
                                                        <EditProfileRoute />
                                                    ),
                                                    children: [
                                                        {
                                                            path: 'email-link-resent',
                                                            element: (
                                                                <EmailResentConfirmationRoute />
                                                            ),
                                                        },
                                                        {
                                                            path: 'password',
                                                            element: (
                                                                <EditPasswordRoute />
                                                            ),
                                                        },
                                                        {
                                                            path: 'email',
                                                            element: (
                                                                <EditEmailRoute />
                                                            ),
                                                        },
                                                        {
                                                            path: 'language',
                                                            element: (
                                                                <EditLanguageRoute />
                                                            ),
                                                        },
                                                        {
                                                            path: 'name',
                                                            element: (
                                                                <EditNameRoute />
                                                            ),
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    path: '*',
                    element: <Navigate to="/" replace />,
                },
            ])}
        />
    );
}
