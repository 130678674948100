import {
    Box,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';

import { ReactHookFormTextField } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormTextField';
import {
    Localized,
    useLanguage,
} from 'dg-web-shared/common/hooks/LanguageProvider';
import { useEasyForm } from 'dg-web-shared/common/utils/FormHooksUtils';
import {
    RequestStatus,
    ServerRequestState,
    useNavigateOnError,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { useParkingaboServerWrite } from '../../api/ParkingaboApi';
import { ParkingaboHeader } from '../../components/layout/ParkingaboHeader';
import { ParkingaboLayout } from '../../components/layout/ParkingaboLayout';
import { ParkingaboLink } from '../../components/ParkinaboLink';
import {
    computePasswordStrength,
    PasswordStrengthIndicator,
} from '../../components/PasswordStrengthIndicator';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { envIsProduction } from 'dg-web-shared/lib/Environment';
import { ParkingaboButton } from '../../components/layout/ParkingaboButton';
import { Legal } from 'dg-web-shared/lib/legal/legal_module_imports';
import { useEffect, useState } from 'react';
import { GenericFormSubmitError } from '../../shared/GenericFormSubmitError.tsx';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers.tsx';
import { useParamOrNull } from 'dg-web-shared/lib/ReactRouterHelpers.ts';
import { usePublicBadgeValidationState } from './ParkingaboBadgeValidationRoute.tsx';
import { useUnauthenticatedTenant } from '../../components/layout/UnauthenticatedTenantProvider.tsx';
import { usePublicCustomerValidationState } from './ParkingaboCustomerValidationRoute.tsx';
import { FeedbackPopup } from '../../components/FeedbackPopup.tsx';
import { ParkingaboAsyncLoadedSection } from '../../components/layout/ParkingaboAsyncLoadedSection.tsx';
import { PublicValidationState } from './ParkingaboSharedRegistrationValidationRoute.tsx';

enum Fields {
    Email = 'email',
    Password = 'password',
    Language = 'language',
}

interface RegisterPayload {
    [Fields.Email]: string;
    [Fields.Password]: string;
    [Fields.Language]: string;
}

interface RegisterResponse {
    confirmUrl?: string;
}

export function ParkingaboRegistrationRoute() {
    const [submitState, submit] = useParkingaboServerWrite<
        RegisterPayload,
        RegisterResponse
    >(() => ({
        url: '/ui-api/parkingabo/user',
    }));

    return (
        <ParkingaboRegistrationForm
            submitState={submitState}
            onSubmit={submit}
            basePath={'/register'}
        />
    );
}

interface TenantRegistrationPayload extends RegisterPayload {
    tenantId: number;
}

export function ParkingaboTenantRegistrationRoute() {
    const { tenantId, allowSignup } = useUnauthenticatedTenant();

    const [submitState, submit] = useParkingaboServerWrite<
        TenantRegistrationPayload,
        RegisterResponse
    >(() => ({
        url: `/ui-api/parkingabo/${tenantId}/user`,
    }));

    if (!allowSignup) {
        return <Navigate to="/login" />;
    }

    return (
        <ParkingaboRegistrationForm
            submitState={submitState}
            onSubmit={(data: RegisterPayload) => {
                submit({
                    [Fields.Email]: data[Fields.Email],
                    [Fields.Language]: data[Fields.Language],
                    [Fields.Password]: data[Fields.Password],
                    tenantId: tenantId,
                });
            }}
            basePath={`/${tenantId}/register`}
            tenantId={tenantId}
        />
    );
}

interface RegisterWithBadgePayload extends RegisterPayload {
    badgeLabelNr: string;
}

export function ParkingaboRegistrationWithBadge() {
    const badgeLabelNr = useParamOrNull('badgeLabelNr');
    const [badgeValidationState] = usePublicBadgeValidationState(badgeLabelNr);
    const [submitState, submit] = useParkingaboServerWrite<
        RegisterWithBadgePayload,
        RegisterResponse
    >(() => ({
        url: '/ui-api/parkingabo/user/with-badge',
    }));
    useNavigateOnError(badgeValidationState, '/login');

    if (badgeLabelNr === null) {
        return <Navigate to="/login" />;
    }

    return (
        <ParkingaboAsyncLoadedSection
            state={badgeValidationState}
            render={data => {
                switch (data.validationState) {
                    case PublicValidationState.NO_LOGIN:
                        return (
                            <ParkingaboRegistrationForm
                                submitState={submitState}
                                onSubmit={(data: RegisterPayload) => {
                                    submit({
                                        [Fields.Email]: data[Fields.Email],
                                        [Fields.Language]:
                                            data[Fields.Language],
                                        [Fields.Password]:
                                            data[Fields.Password],
                                        badgeLabelNr: badgeLabelNr,
                                    });
                                }}
                                basePath={`/register-with-badge/${badgeLabelNr}`}
                            />
                        );
                    case PublicValidationState.EMAIL_CONFIRMATION_PENDING:
                    case PublicValidationState.ONBOARDING_PENDING:
                    case PublicValidationState.ONBOARDING_COMPLETED:
                        return <Navigate to="/login" />;
                }
            }}
        />
    );
}

interface RegisterWithCustomerPayload extends RegisterPayload {
    customerNr: string;
}

export function ParkingaboRegistrationWithCustomer() {
    const customerNr = useParamOrNull('customerNr');
    const [customerValidationState] =
        usePublicCustomerValidationState(customerNr);
    const [submitState, submit] = useParkingaboServerWrite<
        RegisterWithCustomerPayload,
        RegisterResponse
    >(() => ({
        url: '/ui-api/parkingabo/user/with-customer',
    }));
    useNavigateOnError(customerValidationState, '/login');

    if (customerNr === null) {
        return <Navigate to="/login" />;
    }

    return (
        <ParkingaboAsyncLoadedSection
            state={customerValidationState}
            render={data => {
                switch (data.validationState) {
                    case PublicValidationState.NO_LOGIN:
                        return (
                            <ParkingaboRegistrationForm
                                submitState={submitState}
                                onSubmit={(data: RegisterPayload) => {
                                    submit({
                                        [Fields.Email]: data[Fields.Email],
                                        [Fields.Language]:
                                            data[Fields.Language],
                                        [Fields.Password]:
                                            data[Fields.Password],
                                        customerNr: customerNr,
                                    });
                                }}
                                basePath={`/register-with-customer/${customerNr}`}
                            />
                        );
                    case PublicValidationState.EMAIL_CONFIRMATION_PENDING:
                    case PublicValidationState.ONBOARDING_PENDING:
                    case PublicValidationState.ONBOARDING_COMPLETED:
                        return <Navigate to="/login" />;
                }
            }}
        />
    );
}

function ParkingaboRegistrationForm({
    submitState,
    onSubmit,
    basePath,
    tenantId,
}: {
    submitState: ServerRequestState<RegisterResponse, ValidationData>;
    onSubmit: (data: RegisterPayload) => void;
    basePath: string;
    tenantId?: number;
}) {
    return (
        <ParkingaboLayout>
            <Box>
                <ParkingaboHeader
                    to={'/login'}
                    linkLabel={
                        <Localized
                            de="Zurück"
                            fr="Retourner"
                            it="Indietro"
                            en="Back"
                        />
                    }
                />
                <Typography
                    variant="h1"
                    sx={{
                        marginTop: theme => theme.spacing(3),
                    }}
                >
                    <Localized
                        de="Registrierung"
                        fr="Inscription"
                        it="Registrazione"
                        en="Registration"
                    />
                </Typography>
                <Typography
                    variant="h3"
                    sx={{
                        marginBottom: theme => theme.spacing(5),
                        marginTop: theme => theme.spacing(2),
                    }}
                >
                    <Localized
                        de="Zugriffdaten erfassen"
                        fr="Saisir les données d'accès"
                        it="Inserire i dati d'accesso"
                        en="Enter your access data"
                    />
                </Typography>
            </Box>

            <ParkingaboRegistrationFormBody
                onSubmit={onSubmit}
                submitState={submitState}
                basePath={basePath}
                tenantId={tenantId}
            />

            <Outlet />
        </ParkingaboLayout>
    );
}

enum RegistrationStep {
    EMAIL = 'EMAIL',
    PASSWORD = 'PASSWORD',
}

function ParkingaboRegistrationFormBody({
    submitState,
    onSubmit,
    basePath,
    tenantId,
}: {
    submitState: ServerRequestState<RegisterResponse, ValidationData>;
    onSubmit: (data: RegisterPayload) => void;
    basePath: string;
    tenantId?: number;
}): JSX.Element {
    const [step, setStep] = useState<RegistrationStep>(RegistrationStep.EMAIL);
    const [email, setEmail] = useState('');

    switch (step) {
        case RegistrationStep.EMAIL:
            return (
                <RegistrationEmailForm
                    nextStep={() => setStep(RegistrationStep.PASSWORD)}
                    setEmail={(e: string) => setEmail(e)}
                    tenantId={tenantId}
                />
            );
        case RegistrationStep.PASSWORD:
            return (
                <RegistrationPasswordForm
                    submitState={submitState}
                    onSubmit={onSubmit}
                    email={email}
                    basePath={basePath}
                    tenantId={tenantId}
                />
            );
    }
}

enum EmailValidationSuccessResponse {
    UNUSED = 'UNUSED',
    ALREADY_EXISTS = 'ALREADY_EXISTS',
}

interface EmailValidationPayload {
    email: string;
    tenantId?: number;
}

function RegistrationEmailForm({
    nextStep,
    setEmail,
    tenantId,
}: {
    nextStep: () => void;
    setEmail: (e: string) => void;
    tenantId?: number;
}) {
    const [showEmailAlreadyUsedPopup, setShowEmailAlreadyUsedPopup] =
        useState(false);
    const { language } = useLanguage();
    const navigate = useNavigate();

    const [validateEmailState, validateEmail] = useParkingaboServerWrite<
        EmailValidationPayload,
        { response: EmailValidationSuccessResponse }
    >(() => ({
        url: '/ui-api/parkingabo/user/email/validation',
    }));

    const {
        formInfo: { control, handleSubmit, watch },
        genericSubmitError,
    } = useEasyForm(undefined, validateEmailState, language, {
        defaultValues: {
            [Fields.Email]: '',
        },
    });

    const email = watch(Fields.Email);

    useServerSuccessEffect(validateEmailState, data => {
        switch (data.response) {
            case EmailValidationSuccessResponse.UNUSED:
                setEmail(email);
                nextStep();
                break;
            case EmailValidationSuccessResponse.ALREADY_EXISTS:
                setShowEmailAlreadyUsedPopup(true);
        }
    });

    return (
        <form
            style={{ height: '100%' }}
            onSubmit={handleSubmit((d: { email: string }) =>
                validateEmail({ email: d.email, tenantId: tenantId }),
            )}
        >
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flex: 1,
                    flexDirection: 'column',
                }}
            >
                <Box>
                    <ReactHookFormTextField
                        name={Fields.Email}
                        required={true}
                        control={control}
                        trimOnBlur={true}
                        type="email"
                        label={
                            <Localized
                                de="E-Mail"
                                fr="E-mail"
                                it="E-mail"
                                en="Email"
                            />
                        }
                    />

                    <GenericFormSubmitError
                        error={genericSubmitError}
                        submitState={validateEmailState}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ParkingaboButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={email.length <= 0}
                        sx={{
                            marginTop: theme => theme.spacing(3),
                            width: '50%',
                        }}
                        loading={
                            validateEmailState.status === RequestStatus.PENDING
                        }
                    >
                        <Localized
                            de="Weiter"
                            fr="Continuer"
                            it="Continua"
                            en="Continue"
                        />
                    </ParkingaboButton>
                </Box>

                <FeedbackPopup
                    open={showEmailAlreadyUsedPopup}
                    color="warning"
                    onAbort={() => {
                        setShowEmailAlreadyUsedPopup(false);
                    }}
                    abortLabel={
                        <Localized
                            de="Zurück"
                            fr="Retourner"
                            it="Indietro"
                            en="Back"
                        />
                    }
                    onConfirm={() => navigate('/login')}
                    confirmLabel={
                        <Localized
                            de="Einloggen"
                            fr="Ouvrir une session"
                            it="Accedi"
                            en="Log in"
                        />
                    }
                    title={
                        <Localized
                            de="E-Mail bereits registriert"
                            fr="E-mail déjà enregistré"
                            it="E-mail già registrato"
                            en="Already registered e-mail"
                        />
                    }
                >
                    <p>
                        <Localized
                            de="Sie sind bereits bei Parkingabo mit dieser E-Mail-Adresse registriert."
                            fr="Vous êtes déjà enregistré dans Parkingabo avec cette adresse e-mail."
                            it="È già registrato in Parkingabo con questo indirizzo e-mail."
                            en="You are already registered in Parkingabo with this e-mail address."
                        />
                    </p>
                    <p>
                        <Localized
                            de="Melden Sie sich mit Ihren Zugangsdaten an und wiederholen Sie den Registrierungsvorgang. Alternativ können Sie auch eine andere E-Mail-Adresse verwenden."
                            fr="Connectez-vous avec vos données d'identification et répétez la procédure d'enregistrement. Alternativement, utilisez une autre adresse e-mail."
                            it="Acceda con le sue credenziali e ripeta la procedura di registrazione. In alternativa utilizzi un altro indirizzo e-mail."
                            en="Log in with your credentials and repeat the registration procedure. Alternatively, use another email address."
                        />
                    </p>
                </FeedbackPopup>
            </Box>
        </form>
    );
}

function RegistrationPasswordForm({
    submitState,
    onSubmit,
    email,
    basePath,
    tenantId,
}: {
    submitState: ServerRequestState<RegisterResponse, ValidationData>;
    onSubmit: (data: RegisterPayload) => void;
    email: string;
    basePath: string;
    tenantId?: number;
}) {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const {
        formInfo: { control, watch, handleSubmit, setValue },
        genericSubmitError,
    } = useEasyForm(undefined, submitState, language, {
        defaultValues: {
            [Fields.Email]: email,
            [Fields.Password]: '',
            [Fields.Language]: language,
        },
    });

    useEffect(() => {
        setValue(Fields.Language, language);
    }, [language]);

    useServerSuccessEffect(submitState, data => {
        const confirmUrl =
            !envIsProduction() && data?.confirmUrl
                ? '?confirmUrl=' + encodeURI(data.confirmUrl)
                : '';
        const baseUrl = `${basePath}/email-sent` + confirmUrl;
        navigate(baseUrl, { replace: true });
    });

    const password = watch(Fields.Password);

    const passwordStrength = computePasswordStrength(password);
    const [repeatPassword, setRepeatPassword] = useState('');
    const [accepted, setAccepted] = useState(false);
    return (
        <form
            style={{ height: '100%' }}
            onSubmit={handleSubmit((d: RegisterPayload) => onSubmit(d))}
        >
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flex: 1,
                    flexDirection: 'column',
                }}
            >
                <Box>
                    <ReactHookFormTextField
                        label={
                            <Localized
                                de="Passwort"
                                fr="Mot de passe"
                                it="Password"
                                en="Password"
                            />
                        }
                        control={control}
                        required={true}
                        name={Fields.Password}
                        type="password"
                        margin="normal"
                    />
                    <TextField
                        label={
                            <Localized
                                de="Passwort bestätigung"
                                fr="Confirmer le mot de passe"
                                it="Conferma password"
                                en="Repeat password"
                            />
                        }
                        value={repeatPassword}
                        type="password"
                        onChange={e => setRepeatPassword(e.target.value)}
                        margin="normal"
                    />
                    {repeatPassword.length > 0 &&
                        password !== repeatPassword && (
                            <Typography color="error">
                                <Localized
                                    de="Die zwei Passwörter stimmen nicht überein"
                                    fr="Les deux mots de passe ne correspondent pas"
                                    it="Le due password non corrispondono"
                                    en="The two passwords do not match"
                                />
                            </Typography>
                        )}
                    <PasswordStrengthIndicator
                        passwordStrength={passwordStrength.strength}
                    />
                    <GenericFormSubmitError
                        error={genericSubmitError}
                        submitState={submitState}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        sx={{
                            color: theme => theme.palette.success.dark,
                            fontWeight: 'bold',
                            marginBottom: 3,
                        }}
                    >
                        {window.location.host.indexOf('cardcenter.') > -1 && (
                            <Localized
                                de="Mit Ihrer Registrierung bestätigen Sie, dass Sie ein Mitarbeiter von UBS Card Center / UMB sind."
                                fr="En vous inscrivant, vous confirmez que vous êtes un employé de UBS Card Center / UMB."
                                it="Effettuando la registrazione conferma di essere un collaboratore UBS Card Center / UMB."
                                en="By registering, you confirm that you are an employee of UBS Card Center / UMB."
                            />
                        )}
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={accepted}
                                onChange={() => setAccepted(a => !a)}
                                color="primary"
                            />
                        }
                        label={
                            <Typography color="primary">
                                <Localized
                                    de="Ich bin mit den "
                                    fr="J’accepte les "
                                    it="Sono d'accordo con le "
                                    en="I agree with the "
                                />
                                <ParkingaboLink
                                    to={
                                        Legal.deriveParkingaboTos(tenantId)[
                                            language
                                        ]
                                    }
                                    target={'_blank'}
                                    underline="always"
                                >
                                    <Localized
                                        de="AGB"
                                        fr="CGV"
                                        it="CG"
                                        en="terms and conditions"
                                    />
                                </ParkingaboLink>
                                <Localized
                                    de=" und der "
                                    fr=" et la "
                                    it=" e con "
                                    en=" and the "
                                />
                                <ParkingaboLink
                                    to={
                                        Legal.deriveParkingaboPrivacy(tenantId)[
                                            language
                                        ]
                                    }
                                    target={'_blank'}
                                    underline="always"
                                >
                                    <Localized
                                        de="Datenschutzerklärung"
                                        fr="déclaration sur la protection des données"
                                        it="l'informativa sulla protezione dei dati"
                                        en="privacy policy"
                                    />
                                </ParkingaboLink>
                                <Localized
                                    de=" einverstanden."
                                    fr="."
                                    it="."
                                    en="."
                                />
                            </Typography>
                        }
                    />
                    <ParkingaboButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={
                            email.length <= 0 ||
                            password.length <= 0 ||
                            password !== repeatPassword ||
                            !accepted
                        }
                        sx={{
                            marginTop: theme => theme.spacing(3),
                            width: '50%',
                            alignSelf: 'flex-end',
                        }}
                        loading={submitState.status === RequestStatus.PENDING}
                    >
                        <Localized
                            de="Bestätigen"
                            fr="Confirmer"
                            it="Conferma"
                            en="Confirm"
                        />
                    </ParkingaboButton>
                </Box>
            </Box>
        </form>
    );
}
